import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import TabList from '@/components/TabList.vue';
import CustomTitle from '@/components/CustomTitle.vue';
import ProductViewBox from '@/components/ProductViewBox.vue';
import { systemModule } from '@/store/modules/system';
import { detailsModule } from '@/store/modules/details';
var ProductDetails = (function (_super) {
    __extends(ProductDetails, _super);
    function ProductDetails() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentImg = '';
        _this.currentID = '';
        _this.productManuals = [];
        return _this;
    }
    ProductDetails.prototype.mounted = function () {
        this.getProjectObj(this.id);
        detailsModule.getProductRecommend();
    };
    Object.defineProperty(ProductDetails.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductDetails.prototype, "productObj", {
        get: function () {
            this.currentID = this.productOptions[0].id;
            if (detailsModule.productObj.preview1_imgurl) {
                this.currentImg = detailsModule.productObj.preview1_imgurl;
            }
            return __assign(__assign({}, detailsModule.productObj), {
                options: {
                    features: detailsModule.productObj.feature,
                    specifications: detailsModule.productObj.specifications,
                    applications: detailsModule.productObj.application,
                    related: detailsModule.productObj.optional_info
                }
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductDetails.prototype, "productOptions", {
        get: function () {
            var list = [];
            Object.entries(this.$t('productDetails.options')).forEach(function (item) {
                list.push({
                    id: item[0],
                    name: item[1]
                });
            });
            this.currentID = list[0].id;
            return list;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductDetails.prototype, "productRecommend", {
        get: function () {
            return detailsModule.productRecommend;
        },
        enumerable: false,
        configurable: true
    });
    ProductDetails.prototype.curretnIDWatch = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.currentID === 'brochure')) return [3, 2];
                        _a = this;
                        return [4, detailsModule.getProductManuals(this.id)];
                    case 1:
                        _a.productManuals = _b.sent();
                        _b.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ProductDetails.prototype.switchImg = function (val) {
        this.currentImg = val;
    };
    ProductDetails.prototype.getProjectObj = function (id) {
        this.currentID = this.productOptions[0].id;
        detailsModule.getProductObj(id);
        document.body.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    };
    ProductDetails.prototype.slideClick = function () {
        detailsModule.setProductRecommend(this.productRecommend.slice(1).
            concat(this.productRecommend.slice(0, 1)));
    };
    __decorate([
        Prop()
    ], ProductDetails.prototype, "id", void 0);
    __decorate([
        Watch('$data.currentID')
    ], ProductDetails.prototype, "curretnIDWatch", null);
    ProductDetails = __decorate([
        Component({
            components: { CustomHead: CustomHead, TabList: TabList, CustomTitle: CustomTitle, ProductViewBox: ProductViewBox }
        })
    ], ProductDetails);
    return ProductDetails;
}(Vue));
export default ProductDetails;
